<template>
<div class="modal fade " id="exampleEmailTemplate" tabindex="-1" role="dialog" aria-labelledby="exampleEmailTemplate" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="text-white mb-0 pb-0">Example Email Template </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div v-html="template">

                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "ExampleEmailTemplate",
    props: ['exampleEmailTemplate'],
    data() {
        return {
          template:'',
        }
    },
    watch: {
        exampleEmailTemplate(currentTemplate) {
          this.template = currentTemplate;

        }
    },

}
</script>

<style>

</style>
