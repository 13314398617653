<template>
<div class="modal fade " id="exampleSmsTemplate" tabindex="-1" role="dialog" aria-labelledby="exampleSmsTemplate" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="text-white mb-0 pb-0">Example Sms Template </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div v-html="template" class="py-2 text-dark">

                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "ExampleSmsTemplate",
    props: ['exampleSmsTemplate'],
    data() {
        return {
          template:'',
        }
    },
    watch: {
         exampleSmsTemplate(currentTemplate) {
          this.template = currentTemplate;
        }
    },

}
</script>

<style>

</style>
