<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Email Template/List</h4>

            </div>
          
            <div class="users-list-table">
                <div class="users-list-filter px-1 my-2 py-2 border rounded">
                    <div>
                        <div class="row align-items-center ">
                            
                            <div class="col-12 col-md-4 ">
                                <div class="form-group">
                                    <label>Template Type</label>
                                    <VueMultiselect v-model="selectedEmailtEmailTemplate" class="" placeholder="select option" :options="emailTempalteTypeOptions" :allow-empty="false" :close-on-select="true" label="name" track-by="value" :show-labels="false" />
                                </div>
                            </div>

                            
                            <div class="col-12 col-md-4 ">
                                <div class="form-group">
                                    <label>Template Name</label>
                                    <input v-model="getEmailTemplatesParamObj.name" class="form-control " type="text" placeholder="Enter Reference">
                                </div>
                            </div>

                            <div class="col-12 col-md-2  ">
                                <div class="form-group">
                                    <label style="visibility: hidden">Filter</label>
                                    <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyEmailTemplateFilter(null)">Filter
                                    </button>
                                </div>
                            </div>
                          
                        </div>
                        
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{ width: '50%' }">NAME</th>

                                        <th class="position-relative " :style="{ width: '50%' }">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(emailTemplate, index) in emailTemplates" :key="index">
                                        <td class="">
                                            <span class="font-weight-bold">{{emailTemplate.name}}</span>
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <span type="button" class="px-0 py-0 view-edit-delete-icon btn" data-toggle="dropdown" :style="{height:'18px'}" aria-haspopup="true" aria-expanded="false">
                                                    <i class="bx bx-dots-vertical-rounded h-100"></i>
                                                </span>
                                                <div class="dropdown-menu">

                                                    <button class="dropdown-item" @click="viewExampleEmailTemplateHandler(emailTemplate.id)">
                                                        View Example Email template
                                                    </button>

                                                    <router-link :to="{name:'emailTemplateEdit',params:{id:emailTemplate.id}}" class="dropdown-item">
                                                        Update 
                                                    </router-link>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ListPagination position="left" :pagination-data="paginateLinks" @getClickedPage="applyEmailTemplateFilter" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="" data-toggle="modal" data-target="#exampleEmailTemplate"></div>
            <div class="" data-toggle="modal" data-target="#exampleSmsTemplate"></div>
            <div class="" data-toggle="modal" data-target="#updateNotification"></div>
            <ExampleEmailTemplate :exampleEmailTemplate="currentEmailTemplate?.example_view ?? ''" />
            <ExampleSmsTemplate :exampleSmsTemplate="currentSmsTemplate?.example_view ?? ''" />
           

        </section>
    </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";
import ExampleEmailTemplate from "@/views/backEnd/settings/notification/includes/ExampleEmailTemplate";
import ExampleSmsTemplate from "@/views/backEnd/settings/notification/includes/ExampleSmsTemplate";


export default {
    name: "EmailTemplateList",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        ListPagination,
      
        AppLayout,
        ExampleEmailTemplate,
        ExampleSmsTemplate

    },

    data() {
        return {
            selectedEmailtEmailTemplate: {
                    name: "None",
                    value: null
                },
         
            
            getEmailTemplatesParamObj: {
                name:'',
                order_by_name: 'ASC',
                paginate: 1,
                pagination: 10,
                page: "",
            },
            currentEmailTemplate: {},
            emailTempalteTypeOptions: [
                // 0:Auth, 1:Appointment, 2:Agreement, 3:Contact, 4:Order, 5:Payment, 6:Refund, 7:Other, 8:Advertisement	
                {
                    name: "None",
                    value: null
                },
                {
                    name: "Auth",
                    value: 0
                },
                {
                    name: "Appointment",
                    value: 1
                },
                {
                    name: "Agreement",
                    value: 2
                },
                {
                    name: "Contact",
                    value: 3
                },
                {
                    name: "Order",
                    value: 4
                },
                {
                    name: "Payment",
                    value: 5
                },
                {
                    name: "Refund",
                    value: 6
                },
                {
                    name: "Advertisement",
                    value: 8
                },
                {
                    name: "Other",
                    value: 7
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            emailTemplates: "appEmailTemplates/emailTemplates",
            paginateLinks: "appEmailTemplates/paginateLinks",

        }),

    },
    watch: {

    },
    methods: {
        ...mapActions({
            getEmailTemplates: "appEmailTemplates/getEmailTemplates",
        }),

        async applyEmailTemplateFilter(pageNumber) {

            this.getEmailTemplatesParamObj.page = pageNumber;
            this.getEmailTemplatesParamObj.type = this.selectedEmailtEmailTemplate?.value ?? "";
            await this.getEmailTemplateList();
        },

     
        async getEmailTemplateList() {
            this.loader(true);
            const response = await this.getEmailTemplates(this.getEmailTemplatesParamObj);
            this.loader(false);
            if (response ?.message) {
                this.showToastMessage(response);
            }
        },
        async getSmsTemplateList() {
            await this.getSmsTemplates();
        },


        viewExampleEmailTemplateHandler(templateId) {
            this.loader(true);
            this.currentEmailTemplate = {};
            const emailTemplateObj = this.emailTemplates.find((emailTemplate) => templateId == emailTemplate.id);
            if (emailTemplateObj !== undefined) {
                this.currentEmailTemplate = emailTemplateObj;
                this.currentSmsTemplate = emailTemplateObj;

                this.loader(false);
                document.querySelector('[data-target="#exampleEmailTemplate"]').click();

            } else {
                this.loader(false);
                this.showToastMessage({
                    type: 'error',
                    message: 'something went wrong'
                });
            }
        },
      

    },

    async mounted() {
        await this.getEmailTemplateList();
    },
};
</script>

<style scoped>
    </style>
